<template>
  <div class="shared-wishlist-page">
    <div class="container">
      <h3 class="title">Shared Wishlist Page</h3>
      <div class="row" v-if="sharedWishItems.length > 0">
        <div
          v-for="wishItem in sharedWishItems"
          :key="wishItem.sku"
          class="col-12 col-md-6 mb-30 col-lg-4 col-xl-3 cst-12"
        >
          <ProductCard :product="wishItem.product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/base/core/components/category-product/ProductCard.vue";

export default {
  name: "SharedWishlist",
  components: {
    ProductCard,
  },
  data: () => ({
    sharedWishItems: [],
  }),
  async mounted() {
    // deIXJpBl1Q1UK9nRoKlbNZ774Be8fcGR

    const { sharing_code } = this.$route.params;

    if (sharing_code) {
      const response = await this.$store.dispatch(
        "account/getSharedWishlist",
        sharing_code
      );
      if (response) this.sharedWishItems = response.items_v2.items;
    }
  },
};
</script>

<style lang="scss" scoped>
 
</style>