<template>
  <BlogDetail />
</template>

<script>
import BlogDetail from "@/base/core/components/blog/BlogDetail.vue";
export default {
  components: {
    BlogDetail,
  },
};
</script>

