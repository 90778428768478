<template>
  <div>
    <NotifyModal
      :show="showNotifyModal"
      :product-sku="modalProduct && modalProduct.sku"
      @close="closeNotifyModal"
    />
    <div class="compare-page">
      <Breadcrumbs />
      <b-container>
        <h1>{{ $t("compare_product") }}</h1>
        <div v-if="products.length == 0">
          <div class="no-data-found">
            <!-- <img src="@/assets/data-not-found.png" /> -->
            <p>{{ $t("no_data_found") }}</p>
          </div>
        </div>
        <div v-else class="compare-wrap">
          <b-row>
            <b-col lg="3" v-show="!mobileCheck">
              <div class="compare-title">
                <div
                  class="blank-space"
                  :style="{ height: topBoxHeight && topBoxHeight + 25 + 'px' }"
                ></div>
                <ul class="attribute-title">
                  <li
                    v-for="(attr, idx) in infoAttrKeys"
                    :key="attr.attribute_id"
                    :style="{
                      height:
                        sideTitlesHeight && sideTitlesHeight[idx] + 1 + 'px',
                    }"
                  >
                    <span>
                      {{ attr.label }}
                    </span>
                  </li>
                </ul>
              </div>
            </b-col>
            <b-col lg="9">
              <div class="compare-slider">
                <ClientOnly>
                  <VueSlickCarousel
                    v-bind="settings"
                    class="product-multi-slider compare-list"
                  >
                    <div
                      class="pm-slider-box"
                      v-for="(product, idx) in products"
                      :key="product.sku"
                    >
                      <div class="pm-box">
                        <div
                          class="top"
                          :ref="'top-box' + idx"
                          :style="{
                            height: topBoxHeight && topBoxHeight + 25 + 'px',
                          }"
                        >
                          <div
                            class="remove-product"
                            @click="remove(product.sku)"
                          >
                            <b-icon icon="trash"></b-icon>
                          </div>

                          <div class="img-wrap">
                            <div
                              class="pm-img"
                              :style="`background-image: url('${
                                product.thumbnail.medium
                                  ? product.thumbnail.medium
                                  : product.thumbnail.url
                              }');`"
                            ></div>
                          </div>

                          <div class="pm-more">
                            <div class="pm-other-info">
                              <b-link
                                :to="`/${product.url_key}`"
                                class="pm-card"
                                ><div class="pm-name">{{ product.name }}</div>
                              </b-link>

                              <Rating
                                :stars="product.rating_summary"
                                :noOfRatings="product.review_count"
                              />

                              <span
                                v-if="
                                  product.price_range.minimum_price.final_price
                                    .value ==
                                  product.price_range.maximum_price.final_price
                                    .value
                                "
                                class="d-block price-line"
                              >
                                {{
                                  formatCurrency(
                                    product.price_range.minimum_price
                                      .final_price.value
                                  )
                                }}
                              </span>
                              <span
                                v-if="
                                  product.price_range.minimum_price.final_price
                                    .value !=
                                  product.price_range.maximum_price.final_price
                                    .value
                                "
                                class="d-block price-line"
                              >
                                {{
                                  formatCurrency(
                                    product.price_range.minimum_price
                                      .final_price.value
                                  )
                                }}
                                -
                                {{
                                  formatCurrency(
                                    product.price_range.maximum_price
                                      .final_price.value
                                  )
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="bottom">
                          <div class="extra-info">
                            <ul class="attribute" :ref="`attr-${idx}`">
                              <li
                                v-for="(info, idx1) in productInfoAttr(
                                  product.sku
                                )"
                                :key="idx1"
                                :style="{
                                  height:
                                    sideTitlesHeight &&
                                    sideTitlesHeight[idx1] + 1 + 'px',
                                }"
                              >
                                <div class="mobile-title" v-show="mobileCheck">
                                  {{ infoAttrKeys[idx1].label }}
                                </div>
                                <span>{{ info }}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </VueSlickCarousel>
                </ClientOnly>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs";
import ClientOnly from "vue-client-only";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { isMobile, isTablet } from "mobile-device-detect";
import { Logger } from "@storefront/core/lib/logger";

import Rating from "@/base/core/components/category-product/Rating.vue";
import NotifyModal from "@/base/core/components/common/NotifyModal.vue";

import config from "@config";

export default {
  name: "Compare",
  components: {
    Rating,
    NotifyModal,
    ClientOnly,
    VueSlickCarousel,
    Breadcrumbs,
  },
  data: () => ({
    topBoxHeight: null,
    sideTitlesHeight: null,
    showNotifyModal: false,
    modalProduct: null,
    settings: {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    },
  }),
  mounted() {
    this.setHeight();
    const bcrumb = {
      current: "Compare",
      routes: [],
    };

    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  computed: {
    products() {
      return this.$store.getters["compare/getProductCompare"];
      // return this.$store.getters["compare/getCompareProductList"];
    },
    infoAttrKeys() {
      return this.products.length
        ? this.products[0].information_attributes
        : [];

      // if (this.products.length) {
      //   let infoAttrs = this.products[0].information_attributes;

      //   this.products.forEach((product) => {
      //     product.information_attributes.forEach((item) => {
      //       if (item.value.toUpperCase().startsWith("X")) {
      //         infoAttrs = infoAttrs.filter(
      //           (item1) => item1.attribute_id != item.attribute_id
      //         );
      //       }
      //     });
      //   });
      //   return infoAttrs;
      // }
      // return [];
    },
    mobileCheck() {
      if (isMobile || isTablet) {
        return true;
      }
      return false;
    },
  },
  watch: {
    products() {
      this.setHeight();
    },
  },
  methods: {
    // ...mapActions({
    //   updateToggleInCompareList: "compare/updateToggleInCompareList",
    // }),
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    productInStock(product) {
      return product.stock_status == "IN_STOCK" ? true : false;
    },
    closeNotifyModal() {
      this.showNotifyModal = false;
    },
    addToNotify(product) {
      this.modalProduct = product;
      this.showNotifyModal = true;
    },
    productInfoAttr(sku) {
      const product = this.products.find((prod) => prod.sku === sku);
      const newData = [];

      this.infoAttrKeys.forEach((element) => {
        const attribute = product.information_attributes.find(
          (attr) => attr.attribute_id == element.attribute_id
        );
        if (attribute && !attribute.value.toUpperCase().startsWith("X")) {
          newData.push(attribute.value);
        } else {
          newData.push("-");
        }
      });

      return newData;
    },
    async addToCart(produtc) {
      switch (produtc.__typename) {
        case "SimpleProduct": {
          const item =
            '{data: {sku: "' + produtc.sku + '", quantity:' + 1 + "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: produtc.__typename,
            item: item,
          });
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            this.$bvModal.show("modal-1");
          }
          break;
        }
        case "ConfigurableProduct":
        case "BundleProduct": {
          this.$router.push(`/${produtc.url_key}`);
          break;
        }

        default:
          break;
      }
    },
    async remove(sku) {
      let retval = await this.$store.dispatch("compare/removeCompareProduct", {
        sku,
      });
      Logger.debug("retval", "removeFromCompare", retval)();
      if (retval == false) {
        const msg = {
          type: "danger",
          title: "",
          text: "something went wrong",
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    setHeight() {
      let topBoxHeight;
      const heights = {};

      this.$nextTick(() => {
        let self = this;

        const imgAttrs = Object.keys(self.$refs).filter((item) =>
          item.startsWith("top-box")
        );

        for (const ref of imgAttrs) {
          if (typeof self.$refs[ref] != "undefined") {
            if (topBoxHeight) {
              if (topBoxHeight < self.$refs[ref].clientHeight) {
                topBoxHeight = self.$refs[ref].clientHeight;
              }
            } else {
              topBoxHeight = self.$refs[ref].clientHeight;
            }
          }
        }

        this.topBoxHeight = topBoxHeight;

        const attributes = Object.keys(self.$refs).filter((item) =>
          item.startsWith("attr-")
        );

        for (const ref of attributes) {
          if (typeof self.$refs[ref] != "undefined") {
            self.$refs[ref].children.forEach((child, idx) => {
              if (heights[idx]) {
                if (heights[idx] < child.clientHeight) {
                  heights[idx] = child.clientHeight;
                }
              } else {
                heights[idx] = child.clientHeight;
              }
            });
          }
        }
        this.sideTitlesHeight = heights;
      });
    },
    // UpdateIsInWishList(product) {
    //   if (this.$store.getters["user/getIsLoggedIn"] == true) {
    //     if (
    //       this.$store.getters["user/isProductInWishlist"](product.sku) == true
    //     ) {
    //       this.$store.dispatch("user/setProductWishlistStatus", {
    //         sku: product.sku,
    //         parentSku: null,
    //       });
    //     } else {
    //       if (product.__typename == "SimpleProduct") {
    //         this.$store.dispatch("user/setProductWishlistStatus", {
    //           sku: product.sku,
    //           parentSku: null,
    //         });
    //       } else {
    //         alert("ya");
    //       }
    //     }
    //   } else {
    //     const msg = {
    //       type: "danger",
    //       title: this.$t("wishlist error"),
    //       text: this.$t("wishlist_logged_in"),
    //     };
    //     this.$store.dispatch("messages/sendMessage", { message: msg });
    //   }
    // },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.$t("compare"),
        },
        {
          name: "keywords",
          content: this.$t("compare"),
        },
        {
          name: "description",
          content: this.$t("compare"),
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Home",
      // all titles will be injected into this template
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },
};
</script>

<style>
</style>