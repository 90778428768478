<template>
  <div>
    <Breadcrumbs />
    <section class="faq-page">
      <b-container>
        <div>
          <h1 class="semiBold-22 title">F.A.Q.</h1>
          <div
            class="faq-box"
            v-for="(section, index) in faq"
            :key="`sec` + index"
          >
            <h2 class="regular-18">{{ section.title }}</h2>

            <div
              class="single-faq"
              v-for="(row, idx) in section.faq.items"
              :key="`sec` + index + `row` + idx"
            >
              <div class="faq-header" v-b-toggle="`sec` + index + `row` + idx">
                {{ row.question }}
              </div>
              <b-collapse
                class="answer"
                :id="`sec` + index + `row` + idx"
                v-html="row.long_answer"
              ></b-collapse>
            </div>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs.vue";
//import SidebarLinks from "@/base/core/components/SidebarLinks";
//import VRuntimeTemplate from "v-runtime-template";
import config from "@config";

export default {
  name: "FaqList",
  components: {
    Breadcrumbs,
    // VRuntimeTemplate,
  },
  computed: {
    faq() {
      return this.$store.getters["faq/getFaq"];
    },
  },
  mounted() {
    const bcrumb = { current: "F.A.Q.", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: "F.A.Q." },
        { name: "keywords", content: "F.A.Q." },
        { name: "description", content: "F.A.Q." },
      ],
      title: "F.A.Q.",
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },

  data: () => ({
    sidebarData: {
      heading: "Categorie",
      options: [
        { displayName: "Gentlemen", linkTo: "#", active: false },
        { displayName: "Women", linkTo: "#", active: false },
        { displayName: "Bicycle clothing sets", linkTo: "#", active: false },
        { displayName: "Children", linkTo: "#", active: false },
        { displayName: "Cycling shoes", linkTo: "#", active: false },
        { displayName: "For your bike", linkTo: "#", active: false },
        { displayName: "For your body", linkTo: "#", active: false },
        { displayName: "Brands", linkTo: "#", active: false },
        { displayName: "Cycling books", linkTo: "#", active: false },
        { displayName: "Gift voucher", linkTo: "#", active: false },
      ],
    },
  }),
};
</script>
<style lang="scss" scoped>
.faq-page {
  h1.title {
    margin-bottom: 20px;
  }
  .faq-box {
    margin-bottom: 30px;
  }
  .single-faq {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
.faq-header {
  background: $grayColor;
  padding: 10px;
  position: relative;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
}
.collapsed {
  &:after {
    content: "+";
    font-size: 24px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
.not-collapsed {
  &:after {
    content: "-";
    font-size: 24px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
.answer {
  padding: 20px 25px 0px 25px;
}
@media screen and (max-width: 670px) {
  .faq-box {
    h2 {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .faq-header {
    padding: 10px 40px 10px 10px;
    font-weight: 400;
  }
  .answer {
    padding: 15px 15px 0;
    font-size: 14px;
  }
}
</style>
