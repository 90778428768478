<template>
  <div class="blog-page">
    <Breadcrumbs />
    <section class="blog-list">
      <b-container>
        <h1 class="semiBold-22">Blog</h1>
        <b-row>
          <b-col cols="12">
            <div class="cst-pagination-bar">
              <span> {{ `Page ${currentPageNr} of ${getTotalPages}` }} </span>
              <b-pagination
                style="margin-left: auto; margin-right: 0"
                v-model="currentPageNr"
                :total-rows="totalBlogs"
                :per-page="blogsPerPage"
                class="cst-pagination"
              >
                <template #page="{ page }">
                  <b-link class="page-link">
                    {{ page }}
                  </b-link>
                </template>
              </b-pagination>
            </div>
            <div class="pagination-bar-down">
              <div class="left">
                <label>{{ $t("view") }}: </label>
                <select v-model="perPageVal" @change="changePerPageVal">
                  <!-- <option value="1">1</option> -->
                  <option value="9">9</option>
                  <option value="24">24</option>
                  <option value="48">48</option>
                  <option value="72">72</option>
                  <option value="98">98</option>
                  <option value="120">120</option>
                </select>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="single-item blog-page-list">
          <b-col
            v-for="(item, index) in currentPage"
            :key="index"
            lg="4"
            md="6"
            cols="6"
          >
            <div class="blog-item">
              <div class="blog-img">
                <img :src="item.featured_image" />
              </div>
              <div class="blog-content">
                <h3 class="blog-item-title regular-18">
                  {{ item.title }}
                </h3>
                <div v-html="item.short_filtered_content"></div>
                <b-button
                  @click="$router.push('/blog/' + item.identifier)"
                  type="button"
                  class="c-btn c-btn-primary"
                  >{{ $t("read_more") }}</b-button
                >
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="cst-pagination-bar">
              <span> {{ `Page ${currentPageNr} of ${getTotalPages}` }} </span>
              <b-pagination
                style="margin-left: auto; margin-right: 0"
                v-model="currentPageNr"
                :total-rows="totalBlogs"
                :per-page="blogsPerPage"
                class="cst-pagination"
              >
                <template #page="{ page }">
                  <b-link class="page-link">
                    {{ page }}
                  </b-link>
                </template>
              </b-pagination>
            </div>
            <div class="pagination-bar-down">
              <div class="left">
                <label>{{ $t("view") }}: </label>
                <select v-model="perPageVal" @change="changePerPageVal">
                  <!-- <option value="1">1</option> -->
                  <option value="9">9</option>
                  <option value="24">24</option>
                  <option value="48">48</option>
                  <option value="72">72</option>
                  <option value="98">98</option>
                  <option value="120">120</option>
                </select>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>
<script>
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs";
import { Logger } from "@storefront/core/lib/logger";
import config from "@config";
export default {
  components: {
    Breadcrumbs,
  },
  mounted() {
    const bcrumb = {
      current: "Blog",
      routes: [],
    };

    this.$store.commit("breadcrumbs/set", bcrumb);
    this.perPageVal = this.$store.getters["blog/getBlogsPerPage"];
  },

  data() {
    return {
      perPageVal: 0,
    };
  },
  computed: {
    currentPageNr: {
      get() {
        return this.$store.getters["blog/getCurrentPageNr"];
      },
      async set(val) {
        Logger.debug("currentPage set val", "Blogs.vue", val)();
        this.$store.dispatch("blog/goToPage", { pageNr: val });
        this.$store.commit("blog/setBlogsPerPage", this.perPageVal);
      },
    },
    totalBlogs() {
      return this.$store.getters["blog/getTotalBlogs"];
    },
    blogsPerPage() {
      return this.$store.getters["blog/getBlogsPerPage"];
    },
    currentPage() {
      return this.$store.getters["blog/getCurrentPage"];
    },
    getTotalPages() {
      return this.$store.getters["blog/getTotalPages"];
    },
  },

  methods: {
    async changePerPageVal() {
      this.$store.commit("blog/setBlogsPerPage", this.perPageVal);
      await this.$store.dispatch("blog/load");
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_title
            : "",
        },
        {
          name: "keywords",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_keywords
            : "",
        },
        {
          name: "description",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_description
            : "",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Home",
      // all titles will be injected into this template
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },
};
</script>
