<template>
  <div>
    <div class="cst-pagination-bar">
      <label
        >Pagina <span>{{ currentPage }}</span> van
        <span>{{ Math.ceil(totalProds / prodsPerPage) }}</span></label
      >

      <div class="view-change ml-0 mobile-work">
        <div @click="paginationConf.isGrid = true" class="grid-view">
          <i class="fas fa-th-large"></i>
        </div>
        <div @click="paginationConf.isGrid = false" class="list-view">
          <i class="fas fa-list"></i>
        </div>
      </div>

      <b-pagination
        :total-rows="totalProds"
        :per-page="prodsPerPage"
        :value="currentPage"
        @change="changePage"
        class="cst-pagination"
        aria-controls="cat-item"
      >
        <template #page="{ page }">
          <b-link class="page-link" :to="goToPage(page)">
            {{ page }}
          </b-link>
        </template>
      </b-pagination>
    </div>

    <div class="pagination-bar-down">
      <div class="left">
        <div class="display-drop">
          <label>{{ $t("view") }}:</label>
          <select :value="prodsPerPage" @change="changePerPage">
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="36">36</option>
          </select>
        </div>
        <div class="view-change desktop-work">
          <div @click="paginationConf.isGrid = true" class="grid-view">
            <i class="fas fa-th-large"></i>
          </div>
          <div @click="paginationConf.isGrid = false" class="list-view">
            <i class="fas fa-list"></i>
          </div>
        </div>
      </div>
      <div class="middle" v-if="isMobileScreen">
        <div @click="changeMobileCategoryFilterShow" class="filter-btn-mobile">
          <i class="fas fa-filter"></i>
          <span>Filter</span>
        </div>
      </div>

      <div class="right">
        <SearchPageContentFilter />
      </div>
    </div>

    <div class="pro-loader" v-if="isLoading">
      <div class="reverse-spinner"></div>
    </div>
    <div v-else-if="totalProds === 0">NO Products Found</div>
    <div
      v-else
      class="row"
      :class="{
        'grid-view': paginationConf.isGrid,
        'list-view': !paginationConf.isGrid,
      }"
    >
      <div
        v-for="product of lists"
        class="col-12 mb-30 col-lg-3 cst-12"
        :key="product.id"
      >
        <ProductCard :product="product" />
      </div>
    </div>

    <div class="cst-pagination-bar">
      <label
        >Pagina <span>{{ currentPage }}</span> van
        <span>{{ Math.ceil(totalProds / prodsPerPage) }}</span></label
      >

      <b-pagination
        :total-rows="totalProds"
        :per-page="prodsPerPage"
        :value="currentPage"
        @change="changePage"
        class="cst-pagination"
        aria-controls="cat-item"
      >
        <template #page="{ page }">
          <b-link class="page-link" :to="goToPage(page)">
            {{ page }}
          </b-link>
        </template>
      </b-pagination>
    </div>

    <div class="pagination-bar-down">
      <div class="left">
        <div class="display-drop">
          <label>{{ $t("view") }}:</label>
          <select :value="prodsPerPage" @change="changePerPage">
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="36">36</option>
          </select>
        </div>
        <div class="view-change">
          <div @click="paginationConf.isGrid = true" class="grid-view">
            <i class="fas fa-th-large"></i>
          </div>
          <div @click="paginationConf.isGrid = false" class="list-view">
            <i class="fas fa-list"></i>
          </div>
        </div>
      </div>
      <div class="right">
        <SearchPageContentFilter />
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import { isMobile } from "mobile-device-detect";
import { mapActions, mapGetters } from "vuex";
import SearchPageContentFilter from "@/base/core/components/search/SearchPageContentFilter";
import ProductCard from "@/base/core/components/category-product/ProductCard.vue";

import chunkArray from "@/base/helpers/chunkArray";

export default {
  name: "SearchFilteredProducts",
  components: {
    ProductCard,
    SearchPageContentFilter,
  },
  data() {
    return {
      paginationConf: {
        isGrid: true,
      },
    };
  },
  mounted() {
    const { page } = this.$route.query;
    const { path } = this.$route;
    const query = { ...this.$route.query };
    if (page && this.totalPages < parseInt(page)) {
      delete query["page"];
    }
    const pageSize = config.products?.perPage || 12;
    const perPage = this.$store.getters["search/getProductsPerPage"];
    if (pageSize != perPage) query["page-size"] = perPage;
    this.$router.replace({ path, query });
  },
  computed: {
    ...mapGetters({
      getMobileCategoryFilterShow: "mobileRes/getMobileCategoryFilterShow",
    }),
    currentSearch() {
      return this.$store.getters["search/getCurrentSearch"];
    },
    isLoading() {
      return this.$store.getters["search/getLoadProducts"];
    },
    currentPage() {
      return this.$store.getters["search/getCurrentPage"];
    },
    prodsPerPage() {
      return this.$store.getters["search/getProductsPerPage"];
    },
    totalProds() {
      return this.$store.getters["search/getTotalProducts"];
    },
    lists() {
      if (this.$store.getters["search/getShowProducts"].length === 0) {
        return [];
      }
      return this.$store.getters["search/getShowProducts"];
    },
    productsChunks() {
      return chunkArray(this.$store.getters["search/getShowProducts"], 12);
    },
    isMobileScreen() {
      return isMobile;
    },
  },
  methods: {
    ...mapActions({
      changeMobileCategoryFilterShow:
        "mobileRes/changeMobileCategoryFilterShow",
    }),
    changePage(pageNo) {
      this.$store.commit("search/setCurrentPage", pageNo);

      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (pageNo != 1) query["page"] = pageNo;
      else delete query["page"];

      this.$router.replace({ path, query });
    },
    goToPage(page) {
      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (page != 1) query["page"] = page;
      else delete query["page"];

      return { path, query };
    },
    changePerPage(event) {
      const perPage = +event.target.value;
      const { path } = this.$route;
      const query = { ...this.$route.query };

      const pageSize = config.products?.perPage;

      if (perPage != pageSize) query["page-size"] = perPage;
      else delete query["page-size"];
      delete query["page"];

      this.$router.replace({ path, query });
      this.$store.commit("search/setProductsPerPage", perPage);
    },
  },
};
</script>

<style lang="scss" scoped></style>
