<template>
   <CustomerServiceDetail />
</template>

<script>
import CustomerServiceDetail from "@/esf_antarctica_lid/core/components/customer-service/CustomerServiceDetail.vue"
export default {
  name: "Contact",
  components: {
  CustomerServiceDetail
  },
  
};
</script>

