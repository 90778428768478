<template>
  <section class="customer-service-page">
    <div class="container">
      <div class="row">
        <div class="col col-md-12">
          <div class="page-title">
            <v-runtime-template v-if="contactService.content"
              :template="`<h3 class='cms-content'>${contactService.content}</h3>`"></v-runtime-template>
          </div>
        </div>
        <div class="categories col col-md-12">
          <div class="faq-box" v-for="(section, index) in faq" :key="`sec` + index">
            <h3 class="faq-box-title">{{ section.title }}</h3>

            <div class="single-faq" v-for="(row, idx) in section.faq.items" :key="`sec` + index + `row` + idx">
              <div class="faq-header" v-b-toggle="`sec` + index + `row` + idx">
                {{ row.question }}
              </div>
              <b-collapse class="answer" :id="`sec` + index + `row` + idx" v-html="row.long_answer"></b-collapse>
            </div>
          </div>

          <!-- <ul class="faq-list">
            <li v-for="item in faq" v-bind:key="`cat_` + item.category_id">
              <b-link @click="scrollTo('cat_' + item.category_id)">
                <span class="faq-list-icon"><font-awesome-icon icon="caret-right" /></span>
                {{ item.title }}</b-link>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
// import cmsBlockSimple from "@/esf_antarctica_lid/core/components/common/BlockSimple.vue";
import config from "@config";

export default {
  components: {
    // cmsBlockSimple,
    VRuntimeTemplate
  },

  computed: {
    contactService() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "customer_service_page"
      );
    },

    faq() {
      return this.$store.getters["faq/getFaq"];
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_title
            : "",
        },
        {
          name: "keywords",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_keywords
            : "",
        },
        {
          name: "description",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_description
            : "",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Home",
      // all titles will be injected into this template
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },
};
</script>

<style lang="scss" scoped></style>
