<template>
   <ContactDetail />
</template>

<script>
import ContactDetail from "@/base/core/components/contact/ContactDetail.vue"
export default {
  name: "Contact",
  components: {
  ContactDetail
  },
  
};
</script>

