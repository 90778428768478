import { render, staticRenderFns } from "./CustomerServiceDetail.vue?vue&type=template&id=6fa6ebff&scoped=true&"
import script from "./CustomerServiceDetail.vue?vue&type=script&lang=js&"
export * from "./CustomerServiceDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fa6ebff",
  null
  
)

export default component.exports