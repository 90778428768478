<template>
  <section class="customer-service-page">
    <div class="container">
      <div class="row">
        <div class="col col-md-12">
          <div class="page-title">
            <h2 class="semiBold-22">{{ contactService.title }}</h2>
          </div>
        </div>
        <div class="col col-md-12">
          <cmsBlockSimple
            v-if="typeof contactService == 'object'"
            :identifier="contactService.identifier"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import cmsBlockSimple from "@/base/core/components/common/BlockSimple.vue";
import config from "@config";

export default {
  components: {
    cmsBlockSimple,
  },

  computed: {
    contactService() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "customer_service_page"
      );
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_title
            : "",
        },
        {
          name: "keywords",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_keywords
            : "",
        },
        {
          name: "description",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_description
            : "",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Home",
      // all titles will be injected into this template
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
