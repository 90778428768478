<template>
  <CatBanner />
</template>

<script>
//import bannerImage from '"../../assets/advertisementBanner01.jpg'
import CatBanner from "./CatBanner.vue";

export default {
  name: "AdvertisingBanner",
  components: { CatBanner },

  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.banner {
  background-size: cover;
  background-position: 88% 0;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 250px;
  padding: 10px 155px 20px 22px;
  line-height: 1.2;

  a {
    color: $white;

    &:hover {
      color: $white !important;
      background: $black;
      border-color: $borderColor;
    }
  }
}

.banner-heading {
  font-size: 30px;
  letter-spacing: -0.025em;
  font-weight: 500;
  margin-bottom: 10px;
}

.banner-txt {
  font-size: 16px;
  letter-spacing: -0.025em;
  padding-left: 3px;
  margin-bottom: 18px;
}

.btn {
  font-size: 18px;
  font-weight: normal;
  display: inline-block;
  padding: 7px 10px;
  border: 1px solid $white;
  min-width: 150px;
}
</style>
