<template>
  <div>
    <div
      class="overlay-filter"
      :class="{ show: isMobleScreen && getMobileCategoryFilterShow }"
    ></div>
    <div :class="{ 'm-filter': isMobleScreen && getMobileCategoryFilterShow }">
      <div class="filter">
        <transition name="slide-fade">
          <div class="filter-collapse" :class="{ show: filterExpanded }">
            <SearchProductFilterContent @clicked="toggleFilter" />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import SearchProductFilterContent from "@/base/core/components/search/SearchProductFilterContent.vue";
import { isServer } from "@storefront/core/helpers";
import { isMobile } from "mobile-device-detect";
import { mapGetters } from "vuex";
export default {
  name: "SearchProductFilter",
  components: {
    SearchProductFilterContent,
  },
  props: {
    dontShowActiveFilters: {
      type: Boolean,
    },
  },

  created() {
    if (!isServer) {
      window.addEventListener("resize", this.resize);
    }
  },
  mounted() {
    this.overlayIsReady = true;
  },
  destroyed() {
    if (!isServer) {
      window.removeEventListener("resize", this.resize);
    }
  },
  computed: {
    ...mapGetters({
      getMobileCategoryFilterShow: "mobileRes/getMobileCategoryFilterShow",
    }),
    tmp() {
      this.$forceUpdate();
      return this.getMobileCategoryFilterShow;
    },
     isMobleScreen() {
      return isMobile;
    },
  },
  methods: {
    resize() {
      if (window.innerWidth > 991) this.filterExpanded = false;
    },
    toggleFilter() {
      this.filterExpanded = !this.filterExpanded;
    },
    removeSelectedItem(id) {
      const index = this.filterItemsSelected.findIndex(
        (item) => item.id === id
      );
      if (index > -1) {
        this.filterItemsSelected[index].isChecked = false;
      }
    },
  },
  data() {
    return {
      filterExpanded: false,
      overlayIsReady: false,
    };
  },
};
</script>

<style lang="scss">
.b-sidebar > .b-sidebar-footer {
  margin-bottom: 60px;
}
</style>
