<template>
  <div>
    <BestSellers />
    <SearchPageContent  />
  </div>
</template>

<script>
import SearchPageContent from "@/base/core/components/search/SearchPageContent";
import BestSellers from "@/base/core/components/category-product/AdvertisingBanner";
export default {
  name: "ProductsListingSearch",
  components: {
    BestSellers,
    SearchPageContent,
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
