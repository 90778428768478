<template>
   <BrandList />
</template>

<script>
import BrandList from "@/base/core/components/brands/BrandList.vue"
export default {
  name: "Brands",
  components: {
  BrandList
  },
  
};
</script>

