<template>
  <div class="shorting-wrap mt-0">
    <div
      class="selected-filters d-none d-md-flex flex-wrap align-items-center"
    ></div>
    <div class="select-wrapper">
      <b-form-select
        id="sorting"
        :value="sorting"
        @change="changeSorting"
        :options="selectOptions"
        class="select"
      />
    </div>
  </div>
</template>

<script>
import config from "@config";
export default {
  name: "SearchPageContentFilter",
  mounted() {
    const { page } = this.$route.query;
    if (page && this.totalPages < parseInt(page)) {
      const { path } = this.$route;
      const query = { ...this.$route.query };
      delete query["page"];

      this.$router.replace({ path, query });
    }
  },
  computed: {
    selectOptions() {
      const sortOpt = [];
      Object.keys(config.sortingOptions).forEach((element) => {
        const option = {
          value: element,
          text: this.$t(`sortingOptions.${element}`),
        };
        sortOpt.push(option);
      });
      return sortOpt;
    },
    sorting() {
      return this.$store.getters["search/getSorting"];
    },
  },
  methods: {
    changeSorting(sortVal) {
      this.$store.commit("search/setSorting", sortVal);

      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (sortVal !== "default") {
        query["sort"] = sortVal;
      } else {
        delete query["sort"];
      }
      delete query["page"];

      this.$router.replace({ path, query });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-type {
  font-size: 16px;
  font-weight: normal;
  margin-right: 12px;
}

.filter-items {
  font-size: 14px;
  li {
    margin-top: 10px;
    margin-bottom: 10px;
    + li {
      margin-left: 14px;
    }
  }
}

.filter-items-btn {
  border: 1px solid $borderColor;
  padding: 4px 22px;
  transition: none;
  display: block;

  &:hover {
    color: $white !important;
  }
}

.select-wrapper {
  position: relative;
}

select {
  font-size: 12px;
  border: 1px solid $borderColor;
  padding: 8px 55px 8px 10px;
  line-height: 1;
  -moz-appearance: none;
  -webkit-appearance: none;
  &:focus {
    box-shadow: none;
    border-color: currentColor;
  }
}

select::-ms-expand {
  display: none;
}
</style>
