<template>
  <div class="filter">
    <b-link class="brands-opener d-lg-none" @click.prevent="toggleBrands"
      >Merken<span class="plus">+</span></b-link
    >
    <div
      class="brands-overlay"
      @click.prevent="toggleBrands"
      v-bind:class="{ show: brandsExpanded }"
    ></div>
    <div class="filter-collapse" v-bind:class="{ show: brandsExpanded }">
      <div class="d-lg-none brands-top">
        <span class="filter--block-heading">{{ $t("our_brands") }}</span>
        <i class="lnr lnr-cross" @click.prevent="toggleBrands"></i>
      </div>
      <span class="sidebar-heading"></span>
      <ul class="category-list">
        <li
          v-for="(sidebarLink, index) in sidebarData"
          :key="'sidebarLink' + index"
        >
          <!-- {{ sidebarLink.category }} -->

          <b-link
            :to="
              sidebarLink.category && sidebarLink.category.url_path
                ? sidebarLink.category.url_path
                : '/page-not-found'
            "
            >{{ sidebarLink.title }}</b-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarLinks",
  props: ["sidebarData"],
  data: () => ({
    brandsExpanded: false,
  }),
  mounted() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    toggleBrands() {
      this.brandsExpanded = !this.brandsExpanded;
    },
    resize() {
      if (window.innerWidth > 991) this.brandsExpanded = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.filter{
  height: 100%;
}
ul {
  &.category-list {
    font-size: 15px;
    list-style: none;
    padding: 0;
    margin-bottom: 10px;
    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
      a {
        color: $black;
      }
    }
  }
}

</style>
