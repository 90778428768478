<template>
  <div class="contact-us-page">
    <Breadcrumbs />
    <section class="contact-form-page">
      <b-container>
        <b-row>
          <b-col lg="7">
            <div class="contact-text">
              <h1 class="desktop">{{ getCmsData.title }}</h1>
              <v-runtime-template
                v-if="getCmsData.content"
                :template="`<div class='cms-content'>${getCmsData.content}</div>`"
              ></v-runtime-template>
            </div>
          </b-col>
          <b-col lg="5">
            <h1 class="mobile">{{ getCmsData.title }}</h1>
            <span class="contact-form-title">{{ $t("contact_form") }}</span>
            <div class="contact-form">
              <div v-if="contactForm != null">
                <dynamic-forms type="contact" />
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="contact-map">
              <cmsBlockSimple
                v-if="typeof conatactMap == 'object'"
                :identifier="conatactMap.identifier"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import cmsBlockSimple from "@/base/core/components/common/BlockSimple.vue";

import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs.vue";
//import SidebarLinks from "@/base/core/components/SidebarLinks";
import VRuntimeTemplate from "v-runtime-template";
import DynamicForms from "@/base/core/components/common/DynamicForms.vue";
import config from "@config";

export default {
  name: "Contact",
  components: {
    Breadcrumbs,
    cmsBlockSimple,
    //SidebarLinks,
    VRuntimeTemplate,
    DynamicForms,
  },
  computed: {
    conatactMap() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "contact_map"
      );
    },
    getCmsData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "contact_page_block"
      );
    },
    contactForm() {
      return this.$store.getters["forms/getContactForm"];
    },
  },
  mounted() {
    const bcrumb = { current: "Contact", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: "Contact" },
        { name: "keywords", content: "Contact" },
        { name: "description", content: "Contact" },
      ],
      title: "Contact",
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },

  data: () => ({
    sidebarData: {
      heading: "Contact",
    },
  }),
};
</script>

<style lang="scss" scope>
.contact-form-page {
  padding-bottom: 80px;

  .contact-form-title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 35px;
    display: block;
  }
  .contact-text {
    padding-right: 200px;
    h1 {
      font-size: 22px;
    }
    .cms-content {
      font-size: 14px;
      margin-top: 30px;
      p {
        margin-bottom: 20px;
      }
      strong {
        font-weight: 600;
      }
    }
  }
  .contact-form {
    border: 1px solid $borderColor;
    padding: 20px;
    background-color: #bccdd6;
    h2,
    h3 {
      display: none;
    }
    .form-group {
      label {
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: 500;
      }
      .form-control,
      .custom-select {
        border-color: $borderColor;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .btn-info {
      background-color: $borderColor;
      border-color: $borderColor;
      margin: 30px 0 5px auto;
      display: table;
      &:hover {
        color: $borderColor;
        background-color: transparent;
      }
    }
  }
  .mobile {
    display: none;
  }
  .contact-map {
    margin-top: 30px;
    iframe {
      width: 100%;
    }
  }
  .product-form-wrap {
    .product-form-inner {
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 1024px) {
    .contact-text {
      padding-right: 100px;
    }
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 60px;
    div[class*="col-"] {
      &:first-child {
        order: 2;
        margin-top: 40px;
      }
    }
    .contact-form-title {
      margin-bottom: 15px;
    }
    .mobile {
      display: block;
      font-size: 24px;
    }
    .contact-text {
      padding-right: 0px;
      .cms-content {
        margin-top: 15px;
      }
    }
    .contact-form {
      .btn-info {
        padding: 7px 15px;
        font-size: 14px;
      }
    }
  }
}
</style>
