<template>
  <div v-if="slider != null">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="5000"
      indicators
      img-width="1920"
      img-height="300"
      style="text-shadow: 1px 1px 2px #333"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
      class="hero-banner-carousel"
    >
      <b-carousel-slide
        v-for="(bSlide, index) in slider.slides"
        :key="index"
        :style="{ backgroundImage: `url(${bSlide.optImage})` }"
      >
        <div class="container">
          <span class="d-block text-uppercase text-light font-weight-normal">{{
            bSlide.title
          }}</span>
          <v-runtime-template
            v-if="bSlide.description != null"
            :template="bSlide.description"
          ></v-runtime-template>

          <b-link
            v-if="bSlide.link != null"
            :to="bSlide.link"
            class="btn btn-dark ml-30 ml-md-0"
            >{{ bSlide.buttonText }}</b-link
          >
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
import { isMobile } from "mobile-device-detect";
import { isTablet } from "mobile-device-detect";
import { isBrowser } from "mobile-device-detect";
import { Logger } from "@storefront/core/lib/logger";
import { mapGetters } from "vuex";
export default {
  name: "CatBanner",
  components: { VRuntimeTemplate },
  data: () => ({
    slide: 0,
    sliding: false,
  }),
  computed: {
    ...mapGetters({ slider1: "category/getSlider" }),
    slider() {
      //return this.$store.getters["category/getSlider"];
      let slider = this.slider1;

      if (slider != null) {
        if (slider.slides != null) {
          slider.slides.forEach((element) => {
            if (this.mobileCheck == "browser") {
              element.optImage = element.media.url;
            } else if (this.mobileCheck == "tablet") {
              element.optImage = element.media.smaller;
            } else {
              element.optImage = element.media.smallest;
            }
          });
        }
      }

      return slider;
    },
    mobileCheck() {
      if (isBrowser) {
        return "browser";
      }
      if (isMobile && isTablet) {
        return "tablet";
      }
      return "mobile";
    },
  },
  mounted() {
    Logger.debug("slides", "slider", this.slider)();
    Logger.debug("isTablet", "slider", isTablet)();
    Logger.debug("isBrowser", "slider", isBrowser)();
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style lang="scss">
.hero-banner-carousel {
  font-size: 35px;
  line-height: 1;

  .carousel-indicators {
    display: none;

    li {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      outline: none;
      bottom: 11px;
      margin: 0 5px;
      position: relative;

      &.active {
        &:before {
          content: "";
          width: 8px;
          height: 8px;
          background: $black;
          position: absolute;
          top: 6px;
          left: 6px;
          border-radius: 100%;
        }
      }
    }
  }

  .carousel-caption {
    position: static;
    text-align: left;
    padding-top: 27px;
    padding-bottom: 27px;
  }

  .btn {
    font-size: 20px;
    min-width: 213px;
    font-weight: 300;

    &:hover {
      color: $white !important;
      background: lighten(black, 40%);
    }
  }

  .container {
  }
  .discount-value {
    font-size: 176px;
    font-weight: 600;
  }

  .discount-percent {
    font-size: 88px;
  }

  .discount-offer {
    font-size: 80px;
  }

  .neg-margin {
    margin-top: -8px;
  }

  .carousel-item {
    min-height: 323px;
    background-size: cover;
    background-position: 51% 50%;
  }
}
</style>
