<template>
  <BlogList />
</template>

<script>
import BlogList from "@/base/core/components/blog/BlogList.vue";
export default {
  components: {
    BlogList,
  },
};
</script>

