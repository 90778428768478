<template>
   <FaqList />
</template>

<script>
import FaqList from "@/base/core/components/faq/FaqList.vue"
export default {
  name: "FaqPage",
  components: {
  FaqList
  },
  
};
</script>

