<template>
   <CompareDetail />
</template>

<script>
import CompareDetail from "@/base/core/components/compare/CompareDetail.vue"
export default {
  name: "ComparePage",
  components: {
  CompareDetail
  },
  
};
</script>

