var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('NotifyModal',{attrs:{"show":_vm.showNotifyModal,"product-sku":_vm.modalProduct && _vm.modalProduct.sku},on:{"close":_vm.closeNotifyModal}}),_c('div',{staticClass:"compare-page"},[_c('Breadcrumbs'),_c('b-container',[_c('h1',[_vm._v(_vm._s(_vm.$t("compare_product")))]),(_vm.products.length == 0)?_c('div',[_c('div',{staticClass:"no-data-found"},[_c('p',[_vm._v(_vm._s(_vm.$t("no_data_found")))])])]):_c('div',{staticClass:"compare-wrap"},[_c('b-row',[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mobileCheck),expression:"!mobileCheck"}],attrs:{"lg":"3"}},[_c('div',{staticClass:"compare-title"},[_c('div',{staticClass:"blank-space",style:({ height: _vm.topBoxHeight && _vm.topBoxHeight + 25 + 'px' })}),_c('ul',{staticClass:"attribute-title"},_vm._l((_vm.infoAttrKeys),function(attr,idx){return _c('li',{key:attr.attribute_id,style:({
                    height:
                      _vm.sideTitlesHeight && _vm.sideTitlesHeight[idx] + 1 + 'px',
                  })},[_c('span',[_vm._v(" "+_vm._s(attr.label)+" ")])])}),0)])]),_c('b-col',{attrs:{"lg":"9"}},[_c('div',{staticClass:"compare-slider"},[_c('ClientOnly',[_c('VueSlickCarousel',_vm._b({staticClass:"product-multi-slider compare-list"},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.products),function(product,idx){return _c('div',{key:product.sku,staticClass:"pm-slider-box"},[_c('div',{staticClass:"pm-box"},[_c('div',{ref:'top-box' + idx,refInFor:true,staticClass:"top",style:({
                          height: _vm.topBoxHeight && _vm.topBoxHeight + 25 + 'px',
                        })},[_c('div',{staticClass:"remove-product",on:{"click":function($event){return _vm.remove(product.sku)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1),_c('div',{staticClass:"img-wrap"},[_c('div',{staticClass:"pm-img",style:(("background-image: url('" + (product.thumbnail.medium
                                ? product.thumbnail.medium
                                : product.thumbnail.url) + "');"))})]),_c('div',{staticClass:"pm-more"},[_c('div',{staticClass:"pm-other-info"},[_c('b-link',{staticClass:"pm-card",attrs:{"to":("/" + (product.url_key))}},[_c('div',{staticClass:"pm-name"},[_vm._v(_vm._s(product.name))])]),_c('Rating',{attrs:{"stars":product.rating_summary,"noOfRatings":product.review_count}}),(
                                product.price_range.minimum_price.final_price
                                  .value ==
                                product.price_range.maximum_price.final_price
                                  .value
                              )?_c('span',{staticClass:"d-block price-line"},[_vm._v(" "+_vm._s(_vm.formatCurrency( product.price_range.minimum_price .final_price.value ))+" ")]):_vm._e(),(
                                product.price_range.minimum_price.final_price
                                  .value !=
                                product.price_range.maximum_price.final_price
                                  .value
                              )?_c('span',{staticClass:"d-block price-line"},[_vm._v(" "+_vm._s(_vm.formatCurrency( product.price_range.minimum_price .final_price.value ))+" - "+_vm._s(_vm.formatCurrency( product.price_range.maximum_price .final_price.value ))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"extra-info"},[_c('ul',{ref:("attr-" + idx),refInFor:true,staticClass:"attribute"},_vm._l((_vm.productInfoAttr(
                                product.sku
                              )),function(info,idx1){return _c('li',{key:idx1,style:({
                                height:
                                  _vm.sideTitlesHeight &&
                                  _vm.sideTitlesHeight[idx1] + 1 + 'px',
                              })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mobileCheck),expression:"mobileCheck"}],staticClass:"mobile-title"},[_vm._v(" "+_vm._s(_vm.infoAttrKeys[idx1].label)+" ")]),_c('span',[_vm._v(_vm._s(info))])])}),0)])])])])}),0)],1)],1)])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }