<template>
  <div class="blog-page">
    <Breadcrumbs />

    <section class="blog-detail">
      <b-container>
        <!-- <h2 class="semiBold-22">Blog title 1</h2> -->
        <b-row>
          <b-col lg="12" v-if="cmsPageContent != null">
            <div class="blog-content">
              <div class="blog-main-img">
                <img :src="cmsPageContent.first_image" alt="" />
              </div>
              <h1 class="semiBold-30">{{ cmsPageContent.title }}</h1>
              <v-runtime-template
                v-if="cmsPageContent.content"
                :template="`<div class='cms-content'>${cmsPageContent.content}</div>`"
              ></v-runtime-template>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
import { Logger } from "@storefront/core/lib/logger";
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs";
import config from "@config";

export default {
  components: {
    VRuntimeTemplate,
    Breadcrumbs,
  },
  computed: {
    cmsPageContent() {
      return this.$store.getters["blog/getBlog"];
    },
    identifier() {
      return this.$route.params.code;
    },
  },
  watch: {
    identifier(val) {
      Logger.debug("watch identifier", "Blog.vue", val)();
      this.$store.dispatch("blog/loadBlog", {
        identifier: this.$route.params.code,
      });
    },
  },
  async mounted() {
    Logger.debug(
      "this.$route.params.code",
      "Blog.vue",
      this.$route.params.code
    )();
    await this.$store.dispatch("blog/loadBlog", {
      identifier: this.$route.params.code,
    });
    const bcrumb = {
      current: this.cmsPageContent.title,
      routes: [
        {
          name: "Blog",
          route_link: "/blog",
        },
      ],
    };

    this.$store.commit("breadcrumbs/set", bcrumb);
  },

  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.cmsPageContent
            ? this.cmsPageContent.meta_title
            : "",
        },
        {
          name: "keywords",
          content: this.cmsPageContent
            ? this.cmsPageContent.meta_keywords
            : "",
        },
        {
          name: "description",
          content: this.cmsPageContent
            ? this.cmsPageContent.meta_description
            : "",
        },
      ],
      title: this.cmsPageContent
        ? this.cmsPageContent.title
        : "",
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },

  data: () => ({
    sidebarData: {
      heading: "Blog",
    },
  }),
};
</script>
<style lang="scss">
</style>
